import { Stack, StackDivider, Button, Skeleton } from '@chakra-ui/react';
import { IMemberAdminPageProps } from './pageConfig';
import { api } from '@/service/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useFetchApi from '@/hooks/useFetchApi';
import { IMemberRequestData } from '@/models/apiRequest';
import { IMemberDetailsData, IMemberDetailsRepoprtSummary, IReportMemberInfoData } from '@/models/page/report/IReportMemberDetails';
import dayjs from 'dayjs';
import { IRechargePupupProps, RechagePupup } from '@/pages/recharge/rechargePopup';
import { RelationAccountTabs } from './components/relationAccountTabs';
import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store.config';
import { PermissionsId } from '@/models/permissions';
type RepoprtSummary = {
    RelatedReportSummary: IMemberDetailsRepoprtSummary[];
    SelfReportSummary: IMemberDetailsRepoprtSummary[];
    RelatedMemberCount: number;
};
const MemberDetailsHeader = (props: IMemberAdminPageProps) => {
    const { t } = useTranslation();
    const [reportSummary, setReportSummary] = useState<RepoprtSummary>({
        RelatedReportSummary: [],
        SelfReportSummary: [],
        RelatedMemberCount: 0,
    });

    const [isLoaded, setIsLoading] = useState(true);

    const [memberInfo, setMemberInfo] = useState<IReportMemberInfoData>();
    const detectChangeAndFetchHandler = useFetchApi<IMemberRequestData, IMemberDetailsData>(
        api.memberDetails,
        ({ value }) => {
            setIsLoading(false);
            return value;
        },
        ({ value: resp }) => {
            setIsLoading(true);
            const { RelatedReportSummary, SelfReportSummary, RelatedMemberCount, MemberInfo } = resp.data.Data;
            setReportSummary({ RelatedReportSummary, SelfReportSummary, RelatedMemberCount });
            setMemberInfo(MemberInfo);
        },
        [],
        (error) => {
            setIsLoading(true);
        },
    );

    useEffect(() => {
        detectChangeAndFetchHandler({
            DateFrom: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            DateTo: dayjs().format('YYYY-MM-DD'),
            UserName: props.MemberUserName,
            PartnerId: props.MemberPartnerId,
        });
    }, [props]);

    const [rechargePopupProps, setRechargePopupProps] = useState<IRechargePupupProps>({
        isOpen: false,
        partnerId: props.MemberPartnerId,
        agentUserCode: props.AgentUserCode,
        memberLists: props.MemberUserName ? [props.MemberUserName] : [],
        readonly: true,
        onClose: () => {
            setRechargePopupProps((pre) => {
                return { ...pre, isOpen: false };
            });
        },
    });

    const permissionList = useSelector((state: RootState) => state.userStore.permissions);

    return (
        <>
            <Stack
                divider={<StackDivider borderColor="gray.400" />}
                rounded="base"
                direction="row"
                spacing="4"
                padding={4}
                justify="space-between"
                backgroundColor="gray.200">
                <div data-box className="w-full">
                    <div className="flex w-full justify-between">
                        <div className="flex ">
                            <span className="icon icon-profile text-4xl" />
                            <span className="ml-2 text-lg font-extrabold">{memberInfo?.UserName}</span>
                        </div>

                        {permissionList.includes(PermissionsId.MemberRecharge) && (
                            <Button
                                backgroundColor="orange"
                                color="white"
                                size="md"
                                _hover={{ bg: 'orange.500' }}
                                onClick={() => {
                                    setRechargePopupProps((pre) => {
                                        return { ...pre, isOpen: true };
                                    });
                                }}>
                                {t('Lbl_RechargeType_2')}
                            </Button>
                        )}
                    </div>

                    <div className="flex mt-4">
                        <div className="flex flex-col flex-1">
                            <Skeleton isLoaded={isLoaded}>
                                <div>
                                    <span className="font-bold">{t('Th_agentNickName')}:</span>
                                    <span className="pl-4">{memberInfo?.AgentUserCode}</span>
                                </div>
                            </Skeleton>
                            <Skeleton isLoaded={isLoaded}>
                                <div>
                                    <span className="font-bold">{t('Lbl_RegistTime')}:</span>
                                    <span className="pl-4">
                                        {memberInfo?.RegisterDate ? dayjs(memberInfo?.RegisterDate).format('YYYY-MM-DD hh:mm:ss') : ''}
                                    </span>
                                </div>
                            </Skeleton>
                            <Skeleton isLoaded={isLoaded}>
                                <div>
                                    <span className="font-bold">{t('Th_partnerId')}:</span>
                                    <span className="pl-4">
                                        {memberInfo?.PartnerId ? t(`Lbl_Partner_${PARTNERS_CONFIGS_MAP[memberInfo.PartnerId].text}`) : ''}
                                    </span>
                                </div>
                            </Skeleton>
                        </div>
                        <div className="flex flex-col flex-1">
                            <Skeleton isLoaded={isLoaded}>
                                <div>
                                    <span className="font-bold">{t('Txt_NickName')}:</span>
                                    <span className="pl-4">{memberInfo?.MemberNickName}</span>
                                </div>
                            </Skeleton>
                            <Skeleton isLoaded={isLoaded}>
                                <div>
                                    <span className="font-bold">{t('Lbl_RegistDomain')}:</span>
                                    <span className="pl-4">{memberInfo?.RegisterDomain}</span>
                                </div>
                            </Skeleton>
                        </div>
                    </div>
                </div>

                <div data-box className="w-full">
                    <RelationAccountTabs {...reportSummary} />
                </div>
            </Stack>

            <RechagePupup {...rechargePopupProps} key={`RCP-${rechargePopupProps.isOpen}`} />
        </>
    );
};

export default MemberDetailsHeader;
